import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import Schart from "vue-schart";
export default {
  name: "dashboard",
  inject: ['reloadsd'],
  data: function data() {
    return {
      name: localStorage.getItem("ms_username"),
      todoList: [// {
        //     title: "无",
        //     status: false
        // },
      ],
      data: [{
        name: "2018/09/04",
        value: 1083
      }, {
        name: "2018/09/05",
        value: 941
      }, {
        name: "2018/09/06",
        value: 1139
      }, {
        name: "2018/09/07",
        value: 816
      }, {
        name: "2018/09/08",
        value: 327
      }, {
        name: "2018/09/09",
        value: 228
      }, {
        name: "2018/09/10",
        value: 1065
      }],
      options: {
        type: "bar",
        title: {
          text: "最近一周各品类销售图"
        },
        xRorate: 25,
        labels: ["周一", "周二", "周三", "周四", "周五"],
        datasets: [{
          label: "家电",
          data: [234, 278, 270, 190, 230]
        }, {
          label: "百货",
          data: [164, 178, 190, 135, 160]
        }, {
          label: "食品",
          data: [144, 198, 150, 235, 120]
        }]
      },
      options2: {
        type: "line",
        title: {
          text: "最近几个月各品类销售趋势图"
        },
        labels: ["6月", "7月", "8月", "9月", "10月"],
        datasets: [{
          label: "家电",
          data: [234, 278, 270, 190, 230]
        }, {
          label: "百货",
          data: [164, 178, 150, 135, 160]
        }, {
          label: "食品",
          data: [74, 118, 200, 235, 90]
        }]
      }
    };
  },
  components: {
    Schart: Schart
  },
  computed: {
    role: function role() {
      return this.name === "admin" ? "超级管理员" : '普通用户';
    }
  },
  created: function created() {// setTimeout(function name() {
    //     window.location.reload()
    // this.reloadsd()
    // },100)
    // this.$router.go(0)
  },
  methods: {
    changeDate: function changeDate() {
      var now = new Date().getTime();
      this.data.forEach(function (item, index) {
        var date = new Date(now - (6 - index) * 86400000);
        item.name = "".concat(date.getFullYear(), "/").concat(date.getMonth() + 1, "/").concat(date.getDate());
      });
    }
  }
};